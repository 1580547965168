<template>
  <b-card>
    <div class="custom-container">
      <form @submit.prevent="saveData">
        <card>
          <template v-slot:headerTitle>
            <vs-button size="large" transparent class="m-0" to="/helex/gps">
              <i class="fas fa-chevron-left mr-1"></i> Regresar
            </vs-button>
            <h3 class="card-title"><b>Nuevo GPS</b></h3>
          </template>

          <!-- datos gps -->
          <div class="mt-2">
            <h3 class="section-title">Gps</h3>
            <div class="row mt-4">
              <!-- imei -->
              <div class="col-xl-6 mb-3">
                <label for="imei" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 1</em> -
                  Imei</label
                >
                <b-form-input
                  v-model="newGps.Imei"
                  type="number"
                  placeholder="Imei del gps..."
                  autofocus
                  required
                ></b-form-input>
              </div>

              <!-- marca -->
              <div class="col-xl-6 mb-3">
                <label for="marca" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 2</em> -
                  Marca</label
                >
                <b-form-input
                  v-model="newGps.Marca"
                  placeholder="Marca del gps..."
                  required
                ></b-form-input>
              </div>
              <!-- modelo -->
              <div class="col-xl-6 mb-3">
                <label for="modelo" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 3</em> -
                  Modelo</label
                >
                <b-form-input
                  v-model="newGps.Modelo"
                  placeholder="Modelo del gps..."
                  required
                ></b-form-input>
              </div>
              <!-- serie -->
              <div class="col-xl-6 mb-3">
                <label for="serie" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 4</em> -
                  Serie</label
                >
                <b-form-input
                  v-model="newGps.Serie"
                  placeholder="Serie del gps..."
                  required
                ></b-form-input>
              </div>
              <!-- fecha compra -->
              <div class="col-xl-6 mb-3">
                <label for="fechaCompra" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 5</em> -
                  Fecha Compra
                </label>
                <input
                  required
                  v-model="newGps.FechaCompra"
                  type="date"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <!-- datos proveedor -->
          <div class="mt-4">
            <h3 class="section-title">Proveedor</h3>
            <div class="row mt-4">
              <!-- proveedor -->
              <div class="col-xl-6 mb-3">
                <label for="proveedor" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 6</em> -
                  Proveedor</label
                >
                <b-form-input
                  v-model="newGps.Proveedor"
                  placeholder="Proveedor del gps..."
                  required
                ></b-form-input>
              </div>
              <!-- direccion proveedor -->
              <div class="col-xl-6 mb-3">
                <label for="dirProveedor" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 7</em> -
                  Dirección Proveedor</label
                >
                <b-form-input
                  v-model="newGps.DireccionProveedor"
                  placeholder="Dirección del proveedor..."
                  required
                ></b-form-input>
              </div>
              <!-- telefono proveedor -->
              <div class="col-xl-6 mb-3">
                <label for="telProveedor" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 8</em> -
                  Contacto Proveedor</label
                >
                <b-form-input
                  v-model="newGps.TelefonoProveedor"
                  type="number"
                  placeholder="Telefono del proveedor..."
                  required
                ></b-form-input>
              </div>
              <!-- pais proveedor -->
              <div class="col-xl-6 mb-3">
                <label for="paisProveedor" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 9</em> - País
                  Proveedor</label
                >
                <b-form-input
                  v-model="newGps.PaisProveedor"
                  placeholder="País del proveedor..."
                  required
                ></b-form-input>
              </div>
              <!-- ciudad proveedor -->
              <div class="col-xl-6 mb-3">
                <label for="ciudadProveedor" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 10</em> -
                  Ciudad Proveedor</label
                >
                <b-form-input
                  v-model="newGps.CiudadProveedor"
                  placeholder="Ciudad del proveedor..."
                  required
                ></b-form-input>
              </div>
            </div>
          </div>

          <!-- datos instalacion -->
          <div class="mt-4">
            <h3 class="section-title">Instalación</h3>
            <div class="row mt-4">
              <!-- fecha instalacion gps -->
              <div class="col-xl-6 mb-3">
                <label for="fechaInstalacionGps" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 11</em> -
                  Fecha Instalación
                </label>
                <input
                  required
                  v-model="newGps.FechaInstalacionGps"
                  type="date"
                  class="form-control"
                />
              </div>
              <!-- nombre completo instalador -->
              <div class="col-xl-6 mb-3">
                <label for="nombreCompletoInstalador" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 12</em> -
                  Instalador</label
                >
                <b-form-input
                  v-model="newGps.NombreCompletoInstalador"
                  placeholder="Nombre completo del instalador..."
                  required
                ></b-form-input>
              </div>
              <!-- ciudad instalador -->
              <div class="col-xl-6 mb-3">
                <label for="ciudadInstalador" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 13</em> -
                  Ciudad Instalador</label
                >
                <b-form-input
                  v-model="newGps.CiudadInstalador"
                  placeholder="Ciudad del instalador..."
                  required
                ></b-form-input>
              </div>
              <!-- direccion instalador -->
              <div class="col-xl-6 mb-3">
                <label for="direccionInstalador" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 14</em> -
                  Dirección Instalador</label
                >
                <b-form-input
                  v-model="newGps.DireccionInstalador"
                  placeholder="Dirección del instalador..."
                  required
                ></b-form-input>
              </div>
              <!-- telefono instalador -->
              <div class="col-xl-6 mb-3">
                <label for="telInstalador" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 15</em> -
                  Contacto Instalador</label
                >
                <b-form-input
                  v-model="newGps.TelefonoInstalador"
                  type="number"
                  placeholder="Telefono del instalador..."
                  required
                ></b-form-input>
              </div>
            </div>
          </div>

          <!-- datos sim card -->
          <div class="mt-4">
            <h3 class="section-title">Sim Card</h3>
            <div class="row mt-4">
              <!-- numero simcard -->
              <div class="col-xl-6 mb-3">
                <label for="numSimCard" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 16</em> - Sim
                  Card</label
                >
                <b-form-input
                  v-model="newGps.NumeroSimCard"
                  type="number"
                  placeholder="Número de la sim card..."
                  required
                ></b-form-input>
              </div>
              <!-- empresa sim card -->
              <div class="col-xl-6 mb-3">
                <label for="empresaSimCard" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 17</em> -
                  Empresa Sim Card</label
                >
                <b-form-input
                  v-model="newGps.EmpresaSimCard"
                  placeholder="Empresa de la sim card..."
                  required
                ></b-form-input>
              </div>
              <!-- fecha activacion sim card -->
              <div class="col-xl-6 mb-3">
                <label for="fechaInstalacionSimCard" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 18</em> -
                  Fecha Activación Sim Card
                </label>
                <input
                  required
                  v-model="newGps.FechaActivacionSimCard"
                  type="date"
                  class="form-control"
                />
              </div>
              <!-- tipo plan sim card -->
              <div class="col-xl-6 mb-3">
                <label for="tipoPlanSimCard" class="form-label"
                  ><em class="text-danger font-weight-bolder">Paso 19</em> -
                  Tipo Plan Sim Card</label
                >
                <b-form-input
                  v-model="newGps.TipoPlanSimCard"
                  placeholder="Tipo de plan de la sim card..."
                  required
                ></b-form-input>
              </div>
            </div>
          </div>

          <!-- asignar vehiculo -->
          <div class="mt-4">
            <h3 class="section-title">Asignar Vehículo</h3>
            <div class="row mt-4 align-items-center">
              <!-- asignar empresa super admin -->
              <div v-if="persona.rol?.nivel >= 4" class="col-xl-6 mb-3">
                <div>
                  <label for="placaVehiculo" class="form-label">
                    <!-- <em class="text-danger font-weight-bolder">Paso 21</em> - -->
                    Empresa</label
                  >
                  <!-- vue-select -->
                  <div style="background: transparent; padding: 0rem">
                    <v-select
                      v-model="newGps.EmpresaId"
                      class="style-chooser"
                      :reduce="(value) => value.id"
                      :options="listaEmpresas"
                      :get-option-label="(option) => `${option.nombre}`"
                      placeholder="-- seleccionar empresa --"
                    >
                      <!-- required -->
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!newGps.EmpresaId"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No hay empresas para mostrar.</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <!-- asignar a vehiculo -->
              <div class="col-xl-6 mb-3">
                <div>
                  <label for="placaVehiculo" class="form-label">
                    <!-- <em class="text-danger font-weight-bolder">Paso 20</em> - -->
                    Asignar Vehículo</label
                  >
                  <!-- vue-select -->
                  <div style="background: transparent; padding: 0rem">
                    <v-select
                      v-model="newGps.VehiculoId"
                      class="style-chooser"
                      :reduce="(value) => value.id"
                      :options="listaVehiculos"
                      :get-option-label="(option) => `${option.placa}`"
                      placeholder="-- seleccionar vehículo --"
                    >
                      <!-- required -->
                      <!-- <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!newGps.VehiculoId"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template> -->
                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No hay vehículos para mostrar.</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <!-- btn guardar gps -->
              <div class="col">
                <vs-button
                  :loading="isSaving"
                  type="submit"
                  class="ml-auto mt-3"
                  animation-type="vertical"
                >
                  Guardar Gps
                  <template #animate>
                    <i class="fas fa-location-dot"></i
                  ></template>
                </vs-button>
              </div>
            </div>
          </div>
        </card>
      </form>
    </div>
  </b-card>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);

const Swal = require("sweetalert2");
export default {
  data() {
    return {
      persona: {},
      isSaving: false,
      newGps: {
        EmpresaId: null,
        Imei: "",
        Marca: "",
        Modelo: "",
        Serie: "",
        FechaCompra: "",
        Proveedor: "",
        DireccionProveedor: "",
        TelefonoProveedor: "",
        PaisProveedor: "",
        CiudadProveedor: "",
        FechaInstalacionGps: "",
        NombreCompletoInstalador: "",
        CiudadInstalador: "",
        DireccionInstalador: "",
        TelefonoInstalador: "",
        PlacaVehiculo: "",
        VehiculoId: null,
        GpsAsignado: false,
        GpsActivo: true,
        NumeroSimCard: "",
        EmpresaSimCard: "",
        FechaActivacionSimCard: "",
        TipoPlanSimCard: "",
        AjusteHora: null,
      },
      listaVehiculos: [],
      listaEmpresas: [],
      tipoAccion: {
        asignar: "asignado",
        crear: "creado",
      },
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    console.log(this.persona);

    if (this.persona.rol.nivel >= 4) {
      await this.getListEmpresas();
    } else {
      await this.getVehiculosByEmpresa(this.persona.empresa.id);
    }

    this.$isLoading(false);
  },
  methods: {
    getPlacaVehiculo(vehiculoId) {
      return this.listaVehiculos.find((v) => v.id === vehiculoId).placa;
    },
    async addLog(GpsId, PersonaId, VehiculoId, TipoAccion, Fecha) {
      try {
        let newLog = {
          GpsId,
          PersonaId,
          VehiculoId,
          TipoAccion,
          Fecha,
        };
        console.log(newLog, "newLog");
        let res = await this.$store.dispatch("hl_post", {
          path: "VehiculoGpsLog/",
          data: newLog,
        });
        console.log("add log...", res);
        if (res.success) {
          return res;
        }
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    initForm() {
      for (let prop in this.newGps) {
        if (prop === "empresaId" || prop === "vehiculoId") {
          this.newGps[prop] = null;
        } else if (prop === "gpsAsignado") {
          this.newGps[prop] = false;
        } else if (prop === "gpsActivo") {
          this.newGps[prop] = true;
        } else {
          this.newGps[prop] = "";
        }
      }
    },
    async saveData() {
      try {
        this.isSaving = true;

        let newData = {
          ...this.newGps,
          EmpresaId:
            this.persona.rol.nivel >= 4
              ? this.newGps.EmpresaId
              : this.persona.empresa.id,
          PlacaVehiculo: this.newGps.VehiculoId
            ? this.getPlacaVehiculo(this.newGps.VehiculoId)
            : null,
          GpsAsignado: this.newGps.VehiculoId ? true : false,
        };

        console.log(newData, "newData");

        let res = await this.$store.dispatch("hl_post", {
          path: "VehiculoRegistroImeiGPS/",
          data: newData,
        });
        if (res.success) {
          let gpsId = res.data;
          // add log creado
          await this.addLog(
            gpsId,
            this.persona.id,
            this.newGps.VehiculoId,
            this.tipoAccion.crear,
            this.$moment
              .tz(new Date(), "America/Bogota")
              .format("YYYY-MM-DDTHH:mm")
          );
          if (this.newGps.VehiculoId) {
            // add log asignado
            await this.addLog(
              gpsId,
              this.persona.id,
              this.newGps.VehiculoId,
              this.tipoAccion.asignar,
              this.$moment
                .tz(new Date(), "America/Bogota")
                .format("YYYY-MM-DDTHH:mm")
            );
          }
          this.isSaving = false;
          this.initForm();
          Swal.fire({
            title: "Listo!",
            text: "Registro almacenado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK, ver listado",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$router.push("/helex/gps");
            }
          });
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async getVehiculosByEmpresa(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Vehiculo/VehiculosPorEmpresa/" + empresaId,
        });
        console.log("list vehiculos...", res);
        if (res.length > 0) {
          this.listaVehiculos = res;
        } else {
          this.listaVehiculos = [];
        }
      } catch (error) {
        this.listaVehiculos = [];
        console.log("err", error);
      }
    },
    async getListEmpresas() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa/",
        });
        console.log("list empresas...", res);
        if (res.length > 0) {
          this.listaEmpresas = res;
        } else {
          this.listaEmpresas = [];
        }
      } catch (error) {
        this.listaEmpresas = [];
        console.log("err", error);
      }
    },
    async updateVehiculos(newValue, oldValue) {
      if (this.persona.rol.nivel >= 4) {
        if (newValue !== oldValue && newValue !== null) {
          this.listaVehiculos = [];
          await this.getVehiculosByEmpresa(newValue);
        }
      }
    },
  },
  watch: {
    "newGps.EmpresaId": "updateVehiculos",
  },
};
</script>

<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");

/* section title */
.section-title {
  border-left: 5px solid #16a275;
  padding-left: 5px;
}
</style>
